body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shippingItemInfo {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 500px;
  background-color:rgba(255, 255, 255, 0.937);
  z-index:100;
  border-radius:10px;
  box-shadow: 1px 1px 12px black;
}

.addressView {
  background-color: rgba(0,0,0,0.55);
  border-radius: 5px;
  color:white;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  top:100px;
  left:20px;
  z-index: 1;
  width:400px;
  height:220px;
}

.addressInput {
  width:275px;
  height:42px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid grey;
}

.addressContainers {
  position:relative;
  left: 12%;
  top: 12%;
}

.addressMarkerBtn {
  display:flex;
}

.navbarMap {
  position: absolute;
  z-index: 100;
  /* width: 100%; */
}

.navbarMap h3 {
  position: relative;
  bottom: 8px;
  /* left: 48%; */
  left: 195px;
  background-color: rgba(255, 255, 255, 0.878);
  width: fit-content;
  padding: 10px;
  border-radius: 2px;
  text-decoration: none;
}

.navbarMap h3:hover {
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  transform: scale(1.03);
}

.originDiv {
  padding-bottom:10px;
  margin-top:-10px;
}

.mapMarkerImg {
  background-color:rgba(255, 255, 255, 0.319);
  height:38px;
  width:38px;
  padding:4px;
  border-radius:5px;
  margin-left:5px;
  cursor: pointer;
}

.mapMarkerImg:hover {
  background-color: rgba(255, 255, 255, 0.853);
}

.radioButtonSelect {
  display: block;
}

.shippingItemsContainer {
  margin-top: 50px;
  display:flex; 
  flex-direction:row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* overflow: hidden; */
  height: fit-content;
}

.card {
  height: 150px;
  width: 150px;
  position: relative;
  margin: 25px;
  box-shadow: 1px 1px 10px rgb(71, 71, 71);
  border-radius: 10px;
}

.card > label {
  font-size: 22px;
}

.card > input[type="radio"]{
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  position: absolute;
  cursor: pointer;
  outline: none;
}

.card > input[type="radio"]:hover{
  background-color: rgba(126, 126, 126, 0.708);
  position: absolute;
  top: -3px;
  left: -5px;
}

.card > input[type="radio"]:checked{
  border: 1px solid rgb(44, 49, 56);
  background-color: #031195;
  position: absolute;
  top: -3px;
  left: -5px;
}

.cardSmall{
  margin-top: 3px;
  margin-left: 3px;
  height: 30px;
  width: 40px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.577);
  border-radius: 5px;
}

.cardSmall > label {
  font-size: 16px;
  position: absolute;
  left: 50%; 
  top: 50%;
}

.cardSmall > input[type="radio"]{
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  position: absolute;
  box-shadow: 7px 7px 15px rgba(2,28,53,0.08);
  cursor: pointer;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.18);
}

.cardSmall > input[type="radio"]:hover{
  transform: scale(1.05);
}

.cardSmall > input[type="radio"]:checked{
  background-color: #031195;
  position: absolute;
  top: -3px;
  left: -5px;
}

.inputRadioSmall {
  font-weight: 500;
  margin-top: 20px;
  display: flex;
}

.vehicleInputRadioTitle {
  position: relative;
  bottom: 10px;
}

.cardLabel {
  position: absolute;
  text-align: center;
  left: 50%; 
  top: 30%;
  transform: translate(-50%, -50%)
};

.buttonText {
  position: absolute;
}

.carImg {
  position: absolute;
  width: 40%;
  /* left: 50%; 
  top: 70%;
  transform: translate(-50%, -50%); */
  text-align: center;
  left: 45px;
  top: 80px;
  z-index: 2;
}

.vehicleInput {
  width: 220px;
  height: 30px;
  font-size: 16px;
  margin-bottom: 0px;
  border-radius: 3px;
  border: 1px;
  /* border-bottom: .3px solid black; */
  background-color: rgba(174, 174, 174, 0.24);
}

.redBorderValidation {
  border: 1px solid red;
}

.inputForm > h3 {
  margin-bottom: 2px;
}

.inputRadio {
  font-size: 25px;
  font-weight: 500;
  margin-top: 10px;
}

.inputRadio > input[type="radio"]{
  margin: 5px;
  border: 0px;
  width: 10%;
  height: 1.5em;
}

.inputBackBtn {
  position: absolute;
  left: 15px;
  bottom: 15px;
  padding: 5px 10px;
  border: none;
  background-color: rgba(154, 0, 0, 0.701);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
}

.inputBackBtn:hover {
  background-color: #0336895d;
  color: white;
  transform: scale(1.05);
}

.inputNextBtn {
  position: absolute;
  right: 15px;
  bottom: 15px;
  padding: 10px 15px;
  border: 1px solid black;
  background-color: #b93535;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.52);
}

.inputNextBtn:hover {
  background-color: #092f6dc4;
  color: white;
  transform: scale(1.05);
}

.boatInputContainer {
  margin-top: 50px;
}

.trailerInputContainer {
  margin-top: 20px;
}

.trailerInput, .trailerInputSelect {
  width: 170px;
  height: 30px;
  border-radius: 0px;
  border: none;
  font-size: 15px;
  background-color: rgba(78, 128, 255, 0.07);
}

.trailerInputForm {
  margin-bottom: -15px;
}

.trailerInputLeft {
  float: left;
  margin-left: 105px;
}
.trailerInputRight {
  float: right;
  margin-right: 105px;
}

.trailerInputRadio {
  margin-top: 20px;
  font-size: 25px;
  font-weight: 500;
}

.trailerInputRadio > input[type="radio"]{
  margin: 5px;
  border: 0px;
  width: 10%;
  height: 1.5em;
}

.goBtn {
  position: absolute;
  background-color: rgba(181, 17, 17, 0.822);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 30px;
  left: 300px;
  top: 270px;
  font-size: 22px;
  cursor: pointer;
  z-index: 100;
}

.goBtn:hover {
  background-color: rgba(181, 17, 17, 0.942);
  transform: scale(1.05);
}

.goToMapBtn {
  position: absolute;
  right: 15px;
  bottom: 15px;
  padding: 10px 15px;
  border: 1px solid black;
  background-color: #c04646;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.52);
}

.goToMapBtn:hover {
  background-color: rgba(181, 17, 17, 0.942);
  transform: scale(1.05);
}

.vehicleInputContainer {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.recInputContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.stuffInputContainer {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
}

.modifiedRadioBtn {
  position: absolute;
  bottom: 85px;
  left: 25%;
}

.radioBtn {
  opacity: .2;
  z-index: 1000;
}

.spinner {
  position: absolute;
  height: 150px;
  width: 200px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.317);
}

.spinnerText {
  color: rgba(255, 255, 255, 0.77);
  position: absolute;
  left: 50%;
  top: 41%;
  transform: translate(-50%, -50%);
}

.confirmRate {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 500px;
  background: linear-gradient(120deg, rgba(199, 93, 93, 0.278) 0%, rgba(56, 69, 183, 0.232) 84%);
  background-color:rgba(72, 85, 144, 0.834);
  color: white;
  z-index:100;
  border-radius:10px;
  box-shadow: 1px 1px 12px black;
  text-align: center;
  font-size: 30px;
}

.rateInfoDiv {
  margin-top: 150px;
}

.rateInfoDiv > h2 {
  margin-top: 40px;
  color: rgb(255, 255, 255);
}

.vehicleInfoRatePage {
  display: flex;
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.confirmRateBtn {
  margin-top: 40px;
  font-size: 16px;
  font-weight: 600;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  box-shadow: 1px 1px 8px black;
}

.confirmRateBtn:hover {
  transform: scale(1.02);
  background-color: white;
}

.itemTable {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  text-align: left;
  min-width: 550px;
  text-align: center;
}

.arrowDownImg {
  height: 30px;
}

.confirmRateInput input {
  display: block;
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 55px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 24px;
}

.confirmRateInput > form {
  position: relative;
  margin-top: 90px;
}

.submitBtn {
  position: absolute;
  bottom: -70px;
  background-color: #033689e1;
  color: white;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 16px;
  cursor: pointer;
  z-index: 100;
}

.submitBtn:hover {
  background-color: #033689;
}

.confirmRateInput {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 500px;
  background-color:white;
  color: black;
  z-index:100;
  border-radius:10px;
  box-shadow: 1px 1px 12px black;
}

.confirmRateInput h2 {
  position: relative;
  text-align: center;
  top: 20px;
}

.nameInput {
  background: url(./images/name.png) no-repeat scroll 310px 15px;
}

.phoneInput {
  background: url(./images/telephone.png) no-repeat scroll 310px 15px;
}

.emailInput {
  background: url(./images/mail.png) no-repeat scroll 310px 15px;
}

.collectedInfo {
  position: relative;
  left: -100px;
  top: -200px;
  background-color: red;
  height: 300px;
  width: 250px;
  z-index: 10000;
  color: white;
}

/* HOMEPAGE */

.homePageContainer {
  background: linear-gradient(120deg, rgba(199, 93, 93, 0.278) 0%, rgba(56, 69, 183, 0.232) 84%);
  background-color:rgb(0, 0, 0);
  width: 100%;
  height: 100vh;
}

.homePageHero {
  margin-top: -70px;
  width: 100%;
  height: 100vh;
  position: relative;
}

.homePageHeader {
  color: #b67272d7;
  text-shadow: 0px 0px 42px rgba(255, 255, 255, 0.182);
  font-size: 80px;
  position: relative;
  top: 35%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  text-align: center;
}

.homePageHeader:hover {
  color: rgba(228, 208, 208, 0.902);
  text-shadow: 0px 0px 42px rgba(255, 255, 255, 0.253);
}

.slogan {
  color: white;
  font-size: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.quoteBtn {
  position: absolute;
  left: 50%;
  top: 75%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.158);
  color: white;
  border: 3px solid aqua;
  padding: 20px;
  font-size: 25px;
  border-radius: 10px;
}

.quoteBtn2 {
  position: relative;
  left: 40%;
  top: 30%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background-color: #011022;
  color: white;
  border: 3px solid aqua;
  padding: 20px;
  font-size: 25px;
  border-radius: 10px;
}

.quoteBtn3 {
  position: relative;
  left: 0%;
  top: 55%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background-color: #011022;
  color: white;
  border: 3px solid aqua;
  padding: 20px;
  font-size: 25px;
  border-radius: 10px;
}

.quoteBtn:hover {
  background-color: rgba(243, 130, 130, 0.231);
  cursor: pointer;
}

.quoteBtn2:hover, .quoteBtn3:hover {
  background-color: rgba(24, 36, 112, 0.933);
  cursor: pointer;
}

.mapImg {
  position: absolute;
  width: 100%;
  height: 100vh;
  opacity: .025;
}

/* .boxContainer {
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: rgba(0, 0, 0, 0.386);
  z-index: 2;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.092);
} */

.homePage1 {
  /* background-image: linear-gradient(to bottom, #011022, #3d4352, #7a7c88, #bbbbc1, #ffffff); */
  height: 800px;
}

/* NAVBAR */

.navBar {
  position: absolute;
  text-align: center;
  z-index: 1000;
  font-size: 20px;
  width: 100%;
  margin-top: 20px;
}

.navBar a {
  text-decoration: none;
  margin-right: 50px;
  color: rgba(255, 255, 255, 0.809);
}

.navBar a:hover {
  color: white;
  text-shadow: 0px 0px 8px rgba(0, 255, 255, 0.386);
}

.navBar li {
  list-style-type: none;
  display: inline;
}

@keyframes rotate {
  0% {
      transform: rotate(0deg);
  } 

  100% {
      transform: rotate(359deg);
  }
}

/* HOMEPAGE */

.globe {
  width: 100%;
  height: 100vh;
}

.homePage {
  height: 200px;
  text-align: center;
}

.homePage h1 {
  font-size: 40px;
  color: rgb(72, 120, 168);
  text-align: center;
  margin-top: 100px;
}

.homePage1 {
  height: 400px;
  background-color: white;
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
}

.homePageBoxes {
  position: relative;
  width: 300px;
  height: 70px;
  background-color: #444271c7;
  border-radius: 100px;
  box-shadow: 0px 0px 12px #011022;
}

.homePageBoxes:hover {
  box-shadow: 0px 3px 22px rgba(0, 0, 0, 0.75);
}

.homePage1 h3 {
  text-align: center;
  color: white;
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translate(-50%, -50%);
  font-size: 22px;
}

.homePage li {
  list-style-type: none;
  display: inline;
  margin: 30px;
  font-size: 22px;
}

.homePage2 {
  background-color: rgb(244, 244, 244);
  height: 200px;
}

.homePage3 {
  height: 375px;
  width: 100%;
  margin-top: 150px;
  display:flex; 
  flex-direction:row;
}

.homePage3 p {
  width: 65%;
  font-size: 1.5em;
  margin: 25px;
  background-color: rgb(244, 244, 244);
  color: black;
  padding: 20px;
  border-radius: 15px;
  /* box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.222); */
}

.homePage3 h1 {
  width: 450px;
  margin-left: 100px;
  font-size: 50px;
}

.homePage3 li {
  list-style-type: none;
  font-size: 22px;
  font-style: italic;
  margin-left: 20px;
}

.homePage3 span {
  color: blue;
  font-weight: 900;
  font-size: 25px;
  margin-left: 3px;
}

.homePage4Container {
  margin-top: -100px;
}

.homePage4Container p {
  position: relative;
  top: 150px;
  font-size: 24px;
  font-weight: 600;
}

.homePage4 {
  height: 450px;
  background-color: rgb(244, 244, 244);
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.homePage4 p {
  margin: 0;
  padding: 0;
}

.homePage4 img {
  width: 115px;
  position: relative;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
}

.roundIcon {
  margin-top: 200px;
  width: 150px;
  height: 150px;
  border-radius: 100px;
  background-color: rgba(125, 0, 0, 0.58);
  box-shadow: 0px 0px 12px #011022;
}

.furnitureImg, .boxesImg {
  width: 80px!important;
}

.checkboxImg {
  width: 30px;
  margin-bottom: -8px;
  margin-right: 5px;
  margin-left: 10px;
}

.computerShippingImg {
  float: left;
  width: 600px;
  border-radius: 30px;
  margin: 50px;
  filter: grayscale(.5);
}

.homePage5 h1 {
  position: relative;
  top: 100px;
  list-style-type: none;
  font-size: 50px;
  margin: 10px;
}

.homePage5Text {
  position: relative;
  font-style: italic;
  font-size: 24px;
  float: right;
  background-image: -webkit-linear-gradient(135deg, #a56868e8 54%,#804545bf 4%,#c27070 68%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  z-index: 9999995;
  inline-size: 40%;
  overflow-wrap: break-word;
  right: 130px;
  top: 100px;
}

.downText {
  margin-top:20px!important;
  font-weight: 900;
}

.blinkMe {
  animation: blinker 2s linear infinite;
}

.homePage6 {
  position: relative;
  height: 550px;
  width: 100%;
  top: 100px;
  background-color: rgb(244, 244, 244);
  display: inline-block;
  text-align: center;
  color: black;
}

.homePage6Header {
  margin: 40px;
}

.homePage6Img {
  width: 100%;
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.homePage6Img img {
  width: 100px;
  margin: 0px 50px;
}

.homePage6Img img:hover {
  transform: scale(1.1);
}

.homePage6Img div {
  width: 200px;
  margin: 0;
  padding: 0;
  /* background-color: rgba(134, 62, 62, 0.565); */
  padding: 5px;
  border-radius: 20px;
}

.homePage7 {
  height: 500px;
}

.homePage7Text {
  position: relative;
  font-size: 70px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  left: 150px;
  top: 50px;
}

.searchImg {
  height: 400px;
  border-radius: 30px;
  float: right;
  margin: 20px;
  filter: grayscale(.5);
}

.homePage7 {
  margin-top: 200px;
  height: 600px;
}

.homePage7 li {
  float: left;
  list-style-type: none;
  font-size: 50px;
  margin: 50px 10px 10px 10px;
}

.homePage7 h4 {
  position: relative;
  width: 500px;
  font-style: italic;
  font-size: 24px;
  margin: 100px;
  top: 50px;
  color: #7a4b4b;
  background-image: -webkit-linear-gradient(135deg, #a56868 54%,#804545e5 4%,#c27070 68%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer {
  height: 100px;
  width: 100%;
  background-color: #011022;
  color: white;
  text-align: center;
}

.footer h3 {
  position: relative;
  top:20px;
}

.orderSubmittedContainer {
  position: relative;
  text-align: center;
}

.homeQuoteBtns {
  text-align: center;
}

.homeQuoteBtns button {
  background-color: rgb(123, 143, 209);
  border: none;
  font-size: 20px;
  padding: 15px;
  color: white;
  border-radius: 5px;
}

.submittedFormPage {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(40px);
  width: 40%;
  text-align: center;
  background-color: rgba(229, 230, 235, 0.977);
  box-shadow: 1px 1px 19px black;
  border-radius: 100px;
  padding: 50px;
}

.submittedFormBtns {
  background-color: rgb(241, 241, 241);
  border: blue;
  border-radius: 10px;
  padding: 10px;
  margin-top: 40px;
  font-size: 16px;
  width: 190px;
  margin-right: 10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.707);
}

.submittedFormBtns:hover {
  background-color: white;
  cursor: pointer;
}

.mainPage {
  /* background-image: url('../src/images/background.jpg'); */
  background-color: white;
  height: 100vh;
  transform: scale(1);
}

.ratesItemInfo2 b {
  margin-right: 5px;
}

.margin10 {
  margin: 10px;
}

.shipSimplyLogo {
  position: absolute;
  font-size: 35px;
  color: white;
  text-shadow: 2px 2px 12px black;
  left: 20px;
  top: -5px;
}

.shipSimplyLogoHome {
  position: absolute;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.467);
  text-shadow: 2px 2px 12px black;
  left: 20px;
  top: -5px;
}

.shipSimplyLogoHomeVolta {
  position: absolute;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.288);
  text-shadow: 2px 2px 12px black;
  left: 20px;
  top: 8px;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}

.moveItem {
  animation-name : mymove;
  animation-iteration-count : infinite;
  animation-direction : alternate;
  animation-duration : 2s;
}

@keyframes mymove {
  from {transform: translateY(0px);}
  to {transform: translateY(10px)};
}

.moveItemLarge {
  animation-name : mymove2;
  animation-iteration-count : infinite;
  animation-direction : alternate;
  animation-duration : 2s;
}

@keyframes mymove2 {
  from {transform: translateY(0px);}
  to {transform: translateY(30px)};
}

/* TEST MAP */

.App {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
}

.App-map {
  height: 100vh;
  width: 100%;
}

/* ABOUT US */

.aboutUsContainer {
  width: 100%;
  height: 100vh;
  background: linear-gradient(120deg, rgba(199, 93, 93, 0.278) 0%, rgba(56, 69, 183, 0.232) 84%);
  background-color:rgb(0, 0, 0);
  color: white;
}

.aboutUsText {
  width: 50%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
}